import handlers from "@shared/mixins/handlers";
import forEach from "lodash/forEach";

export default {
    methods: {

        //AS--> Resolve one value by checking if method exist in handlers or in this
        resolveValue(value) {
            if (!Array.isArray(value)) {
                if (value in handlers.methods && typeof handlers.methods[value] === 'function') {
                    return handlers.methods[value]();
                }
                else if (value in this && typeof this[value] === 'function') {
                    return this[value]();
                }
                else {
                    return value;
                }
            }

            if (value.length == 1){
                if (value[0] in handlers.methods && typeof handlers.methods[value[0]] === 'function') {
                    return handlers.methods[value[0]]();
                }else if (value[0] in this && typeof this[value[0]] === 'function') {
                    return this[value[0]]()
                }
            }

            if (value.length > 1) {
                const handler = value[0];
                const params = value[1].map((param) => {
                    return this.resolveValue(param)
                });

                if (handler in handlers.methods && typeof handlers.methods[handler] === 'function') {
                    return handlers.methods[handler](...params);
                }else if (handler in this && typeof this[handler] === 'function') {
                    return this[handler](...params);
                }
            }
        },

        //AS--> browse the object and resolve all the values, recursively
        resolveObject(object){

            forEach(object, (value, key) => {
                if(Array.isArray(value) && value[0] && ((value[0] in handlers.methods && typeof handlers.methods[value[0]] === 'function') || (value[0] in this && typeof this[value[0]] === 'function'))){
                    object[key] = this.resolveValue(value);
                }
                else if(typeof value === 'object'){
                    object[key] = this.resolveObject(value);
                }
            })

            return object
        }
    }
}