<template>
  <v-navigation-drawer app :width="280" v-model="localDrawer">
    <div class="v-navigation-drawer__content-inner rounded-lg bg-surface">
      <v-sheet>
        <v-list-item class="nav--menu px-0">
          <div class="w-100 pr-4">
            <ul>
              <li>
                <router-link :to="{ name: 'parameters' }" class="text-darktext">
                  <span class="px-2 d-flex align-items">
                    {{ $t("shared.menu.parameters") }}
                    <ParametersIcon class="ml-4" size="1.4" />
                  </span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'my-account' }" class="text-darktext">
                  <span class="px-2 d-flex align-items">
                    {{ $t("shared.menu.account") }}
                    <v-icon icon="mdi-account-cog" class="ml-4" size="25" />
                  </span>
                </router-link>
              </li>
              <li @click="logout">
                <a class="text-darktext">
                  <span class="px-2 d-flex align-items">
                    {{ $t("shared.menu.logout") }}
                    <ExitIcon class="ml-4" size="1.4" />
                  </span>
                </a>
              </li>

              <li @click="debug" v-if="isLocalhost">
                <a class="text-darktext">
                  <span class="px-2 d-flex align-items">
                    Debug
                    <v-icon icon="mdi-bug" class="ml-4" size="25" />
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </v-list-item>
      </v-sheet>
      <v-sheet class="header-menu">
        <v-list-item class="px-0">
          <img src="@customer/shared/assets/img/logo.svg" class="logo pt-6 pb-3" />
        </v-list-item>
        <v-divider
          :thickness="1"
          class="border-opacity-25 mb-4 mt-0 pt-0"
          color="primary"
        />
        <v-list-item class="px-0">
          <v-text-field
            bg-color="surface-input"
            density="compact"
            append-inner-icon="mdi-magnify"
            :label="$t('shared.menu.search')"
            class="menu-search v--search mt-2"
          />
        </v-list-item>
        <v-list-item class="py-6 px-0">
          <h2 class="text-on-surface text-h2">
            Bonjour,<br />{{ $store.getters.userGetProfile["full_name"] }}
          </h2>
        </v-list-item>

        <v-list-item class="nav--menu px-0">
          <template :key="menu.code" v-for="menu in getMenu">
            <router-link
              v-if="menu.path"
              :to="menu.path"
              v-slot="{ href, navigate, isActive, isExactActive }"
              custom
            >
              <!-- <div
                :class="[
                  'menu-item',
                  (isActive && 'router-link-active') || (isSubActive[href] && 'router-link-active'),
                  isExactActive && 'router-link-exact-active',
                ]"
              > -->
              <a
                :href="href"
                @click="navigate"
                :class="[
                  'text-darktext',
                  (isActive && 'router-link-active') ||
                    (isSubActive[href] && 'router-link-active'),
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <span class="px-2 d-flex align-center">
                  <component
                    :is="menu?.menu?.icon?.name"
                    :class="menu?.menu?.icon?.class"
                    :size="menu?.menu?.icon?.size"
                  />
                  {{ $t(menu.menu.label) }}</span
                >
              </a>
            </router-link>

            <a
              v-if="menu.action"
              @click="$store.dispatch(menu.action, menu.params)"
              ><span class="px-2 d-flex align-center">
                <component
                  :is="menu?.menu?.icon?.name"
                  :class="menu?.menu?.icon?.class"
                  :size="menu?.menu?.icon?.size || '1.4'"
                />
                {{ $t(menu.menu.label) }}
              </span>
            </a>
          </template>
        </v-list-item>
      </v-sheet>
    </div>
  </v-navigation-drawer>
</template>
<style>
.v-navigation-drawer {
  background-color: var(--v-theme-primary);
  border: none;
  position: fixed !important;

  .v-navigation-drawer__content {
    padding: 1.5rem 0.75rem 1.5rem 1.5rem;
    .v-navigation-drawer__content-inner {
      display: flex;
      justify-content: space-between;
      flex-direction: column-reverse;
      height: 100%;
    }
  }
}
</style>
<script src="./Menu.js"></script>
