import api from "@shared/services/api"
import * as immutable from 'object-path-immutable'

const SET_FORM_OPTIONS = 'SET_FORM_OPTIONS';


const mutations = {
  [SET_FORM_OPTIONS] (state, payload) {
    const path = `${payload.code}.${payload.field}`;
    state.forms = immutable.set(state.forms, path, payload.options);
  }
};


const state = {
  forms: {},
};


const getters = {
  getFormOptions: (state) => (code, field, getterStruct) => {
      if (!(code in state.forms)) return [];
      if (!(field in state.forms[code])) return [];
      return state.forms[code][field].get(getterStruct);
  },
}


const actions = {
    async "formOption/fetch"({ commit }, params) {
      let entityType = params._entityType;
      let code = params._code;
      let field = params._field;

      delete params._entityType;
      delete params._code;
      delete params._field;


      return new Promise((resolve, reject) => {
        api.jsonApi(entityType, params).then((data) => {
            commit(SET_FORM_OPTIONS, {
                code: code,
                field: field,
                options: data.model
            });
            resolve('ok');
        }).catch((error) => {
            reject(error);
        })
    })
    }
};


export default {
    state,
    getters,
    actions,
    mutations
}