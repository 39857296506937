import api from "@shared/services/api"
import * as immutable from 'object-path-immutable'

const SET_LIST = 'SET_LIST';
const ADD_TO_LIST = 'ADD_TO_LIST';
const SET_LOADING = 'SET_LOADING';

const mutations = {
    [ADD_TO_LIST] (state, payload) {
        const newList = state.list[payload.code];
        payload.list.forEach((item) => {
            newList.add(item);
        });
        state.list[payload.code].meta = payload.list.meta;
        state.list[payload.code].links = payload.list.links;
        state.list = immutable.set(state.list, payload.code, newList);
        state.lastFetch = immutable.set(state.lastFetch, payload.code, {
            entityType: payload.entityType,
            extraRefreshTypes: payload.extraRefreshTypes ? payload.extraRefreshTypes : [],
            params: payload.params,
        });
    },
    [SET_LIST] (state, payload) {
        // @important
        // Very important example of how to set a nested object in a vuex store : 
        // You must use object-path-immutable !!
        // If you don't, the store will not be reactive when the nested object updates
        state.list = immutable.set(state.list, payload.code, payload.list);
        state.lastFetch = immutable.set(state.lastFetch, payload.code, {
            entityType: payload.entityType,
            extraRefreshTypes: payload.extraRefreshTypes ? payload.extraRefreshTypes : [],
            params: payload.params,
        });
    },
    [SET_LOADING] (state, payload) {
        state.loading = immutable.set(state.loading, payload.code, payload.loading);
    }
}

// This is a nested object, it store every list fetched from the API, under a specific listName
// To refresh a nested object, see comment above
const state = {
    list: {},
    lastFetch: {},
    loading: {}
}

const getters = {
    getDataList: (state) => (code, getterStruct) => {
        if (!(code in state.list) || state.list[code] == undefined) return [];
        let data = []
        
        if(getterStruct){
            data = state.list[code].get(getterStruct);
        }else{
            data = state.list[code];
        }

        return data;
    },
    getDataListCount: (state) => (code) => {
        if (!(code in state.list) || state.list[code] == undefined) return 0;
        if (!('meta' in state.list[code])) return 0;
        return state.list[code].meta.count;
    },
    getDataListLoading: (state) => (code) => {
        if (!(code in state.loading)) return false;
        return state.loading[code];
    },
    getDataListItem: (state) => (code, uuid) => {
        if (!(code in state.list) || state.list[code] == undefined) return undefined;

        const item = state.list[code].where({id: uuid});
        
        // const item = state.list[code].find((item) => {
        //     return item.get('id') == uuid;
        // });
        
        return Array.isArray(item) ? item[0] : item;
    },
    isDataListLastPageFetched: (state) => (code) => {
        if (!(code in state.list) || state.list[code] == undefined) return false;
        if (!('meta' in state.list[code])) return false;
        return state.list[code].meta.page == state.list[code].meta.nbPages;
    }
}

const actions = {
    async "dataList/fetch" ({ commit }, _params) {

        const params = {..._params};
        
        let entityType = params._entityType;
        let code = params._code;
        let extraRefreshTypes = params._extraRefreshTypes;
        let append = params._append ? params._append : false;

        delete params._entityType;
        delete params._code;
        delete params._extraRefreshTypes;
        delete params._append;

        commit(SET_LOADING, {
            code: code,
            loading: true,
        });


        return new Promise((resolve, reject) => {
            api.jsonApi(entityType, params).then((data) => {
                if (append) {
                    commit(ADD_TO_LIST, {
                        code: code,
                        list: data.model,
                        params: params,
                        entityType: entityType,
                        extraRefreshTypes: extraRefreshTypes,
                    });
                }
                else {
                    commit(SET_LIST, {
                        code: code,
                        list: data.model,
                        params: params,
                        entityType: entityType,
                        extraRefreshTypes: extraRefreshTypes,
                    });
                }
                commit(SET_LOADING, {
                    code: code,
                    loading: false,
                });
                resolve('ok');
            }).catch((error) => {
                reject(error);
            })
        })
    },
    async "dataList/refreshByType" ({ commit, state, dispatch }, entityType) {
        if (entityType == 'user_role') {
            return new Promise((resolve, reject) => {
                Promise.all([
                    dispatch('role/getRoles'),
                    dispatch('role/getPermissions'),
                ])
                .then(() => {
                    resolve('ok');
                })
                .catch((error) => {
                    reject(error);
                });
            });
        }

        return new Promise((resolve, reject) => {
            let lastFetch = state.lastFetch;
            let list = state.list;
            let codes = [];
            let params = null;
            for (let key in lastFetch) {
                if (lastFetch[key].entityType == entityType || lastFetch[key].extraRefreshTypes.includes(entityType)) {
                    codes.push(
                        {
                            key: key,
                            entityType: lastFetch[key].entityType,
                            params: lastFetch[key].params,
                            extraRefreshTypes: lastFetch[key].extraRefreshTypes,
                        }
                    );
                }
            }
            if (codes.length == 0) {
                resolve('ok');
                return;
            }
            else {
                const promises = [];
                codes.forEach((code) => {
                    commit(SET_LOADING, {
                        code: code.key,
                        loading: true,
                    });

                    promises.push(
                        api.jsonApi(code.entityType, code.params)
                            .then((data) => {
                                commit(SET_LIST, {
                                    code: code.key,
                                    list: data.model,
                                    params: code.params,
                                    entityType: code.entityType,
                                    extraRefreshTypes: code.extraRefreshTypes,
                                });
                                commit(SET_LOADING, {
                                    code: code.key,
                                    loading: false,
                                });
                            })
                            .catch((error) => {
                                reject(error);
                            }
                        )
                    );
                });
                Promise.all(promises).then(() => {
                    resolve('ok');
                });
            }
        })
    },
}



export default {
    state,
    getters,
    actions,
    mutations
  }