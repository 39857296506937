// import { createRouter, createMemoryHistory } from 'vue-router'
import { createRouter, createWebHistory } from "vue-router";
import store from "@shared/store";
import AuthService from "@shared/services/auth";
import handlers from "@shared/mixins/handlers";
import { cloneDeep } from "lodash";

function init(layout) {
  const routes = [
    {
      path: "/welcome",
      name: "welcome",
      meta: { disconnected: true },
      component: () => import("@shared/components/auth/_Welcome.vue"),
    },
    {
      path: "/accounts",
      name: "accounts",
      meta: { disconnected: true },
      component: () => import("@shared/components/auth/_Accounts.vue"),
    },
    {
      path: "/signin",
      name: "signin",
      meta: { disconnected: true },
      component: () => import("@shared/components/auth/_Signin.vue"),
    },
    {
      path: "/pre-signup",
      name: "pre-signup",
      meta: { disconnected: true },
      component: () => import("@shared/components/auth/_PreSignup.vue"),
    },
    {
      path: "/lookup",
      name: "lookup",
      meta: { disconnected: true },
      component: () => import("@shared/components/auth/_Lookup.vue"),
    },
    {
      path: "/signup",
      name: "signup",
      meta: { disconnected: true },
      component: () => import("@shared/components/auth/_Signup.vue"),
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: { disconnected: true },
      component: () => import("@shared/components/auth/_ResetPassword.vue"),
    },
    {
      path: "/change-password",
      name: "force-change-password",
      meta: { title: "change_password.title", disconnected: false },
      component: () =>
        import("@shared/components/auth/_ForceChangePassword.vue"),
    },
    {
      path: "/no-access",
      name: "no-access",
      meta: { title: "no_access.title", disconnected: false },
      component: layout,
      props: {
        content: [
          {
            type: "module",
            module: "auth.no-access",
            title: "no_access.title",
          },
        ],
        layout: {
          showMenu: false,
        },
      },
    },
  ];

  store.getters.getBORoutes.forEach((element) => {
    if (element.path) {
      let title = element.title ? element.title : "";

      routes.push({
        path: element.path,
        name: element.code,
        redirect: element.redirect != undefined ? element.redirect : false,
        component: layout,
        props: {
          title: title,
          content: element.content,
          layout: element.layout,
          headerBar: element.headerBar,
          routingParams: element.routingParams,
        },
        meta: {
          title,
          disconnected:
            element.disconnected && element.disconnected === true
              ? true
              : false,
        },
      });
    }
  });

  const router = createRouter({
    // history: createMemoryHistory(),
    history: createWebHistory(),
    routes: routes,
  });

  router.beforeEach(async (to) => {
    // Redirige vers le dashboard si pas de correspondance
    if (to.matched.length == 0) {
      return "/";
    }

    // Change le titre de la page
    // document.title = i18n.global.t(to.meta.title)

    let access = false;
    let connected = store.getters.userIsConnected;

    // Si pas d'info sur la connexion un check si on est connecté
    if (connected !== true) {
      connected = await AuthService.status();
      if (connected) {
        await Promise.all([
          store.dispatch("auth/connect"),
          store.dispatch("auth/getProfile"),
        ]);
      }
    }

    // calculate required permission to access this app
    const appPermissionLabel =
      "access app-" + store.getters.getBaseConfigValue("appName");
    if (connected && !store.getters.userHasPermission([appPermissionLabel])) {
      store.dispatch("auth/logout");
      return to.fullPath == "/no-access" ? true : "/no-access";
    }

    if (store.getters.userGetProfile.password_was_reset === true) {
      return to.fullPath == "/change-password" ? true : "/change-password";
    }

    //Check si c'est une url non connecté
    if (to.meta.disconnected === true) {
      // Si c'est une URL connectée et que l'user est connecté, on renvoie l'user vers le dashboard
      return !connected ? true : "/";
    }

    // Si c'est une URL connectée et que l'user n'est pas connecté, on renvoie l'user vers la page de start
    const startUrl = store.getters.getBOConfig.auth.startUrl;
    access = connected ? true : startUrl;

    // Entity pre-loading, si il existe des "routingParams".
    // Pour l'instant, seule la propriété "entityType" existe, on load une entityé par son uuid
    // Dans l'absolu, les URL pourraient contenir autre chose que des uuid, et ceci devrait alors évoluer
    await new Promise((resolve, reject) => {
      if (
        access &&
        to.matched.length == 1 &&
        "props" in to.matched[0] &&
        "default" in to.matched[0].props &&
        "routingParams" in to.matched[0].props.default &&
        to.matched[0].props.default.routingParams != undefined
      ) {
        if ("entityType" in to.matched[0].props.default.routingParams) {
          let queryParams = {};
          if ("entityIncludes" in to.matched[0].props.default.routingParams) {
            queryParams.includes = cloneDeep(
              to.matched[0].props.default.routingParams.entityIncludes
            );

            const resolveValue = function (value) {
              if (!Array.isArray(value)) {
                if (
                  value in handlers.methods &&
                  typeof handlers.methods[value] === "function"
                ) {
                  return handlers.methods[value]();
                } else {
                  return value;
                }
              }

              if (value.length == 1) return handlers.methods[value[0]]();

              if (value.length > 1) {
                const handler = value[0];
                const params = value[1].map((param) => {
                  if (typeof param === "object" && "handler" in param) {
                    return resolveValue([param.handler, param.params]);
                  } else {
                    return param;
                  }
                });

                return handlers.methods[handler](...params);
              }
            };
            const resolveFilterValues = function (filters, resolveFunc) {
              filters.forEach((filter) => {
                if ("value" in filter) {
                  filter.value = resolveFunc(filter.value);
                }
                if ("filters" in filter) {
                  resolveFilterValues(filter.filters, resolveFunc);
                }
              });
            };
            const resolveIncludeValues = function (includes, resolveFunc) {
              includes.forEach((include) => {
                if (typeof include === "object") {
                  if ("includes" in include) {
                    resolveIncludeValues(include.includes, resolveFunc);
                  }
                  if ("filters" in include) {
                    resolveFilterValues(include.filters, resolveFunc);
                  }
                }
              });
            };
            resolveIncludeValues(queryParams.includes, resolveValue);
          }
          store
            .dispatch("entityType/get", {
              _entityType: to.matched[0].props.default.routingParams.entityType,
              _entityId: to.params.uuid,
              _params: queryParams,
            })
            .then(() => {
              store
                .dispatch("entityType/setPageContext", {
                  _entityType:
                    to.matched[0].props.default.routingParams.entityType,
                  _entityId: to.params.uuid,
                  _params: queryParams,
                })
                .then(() => {
                  // Recursively add context=page to all content items
                  const addContextToContent = function (content) {
                    content.forEach((item) => {
                      item.context = "page";
                      if (item.content != undefined) {
                        addContextToContent(item.content);
                      }
                    });
                  };

                  addContextToContent(to.matched[0].props.default.content);

                  console.log("entity preloading from router ok");
                  resolve("ok");
                });
            });
        } else {
          resolve("ok");
        }
      } else {
        resolve("ok");
      }
    });

    return access;
  });

  return router;
}

export { init };
