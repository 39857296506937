<template>
  <svg
    :width="size + 'em'"
    :height="size + 'em'"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.5" cy="9.90002" r="3" fill="#080232" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.9515 16.8084C18.0089 16.9121 17.9794 17.0415 17.8844 17.1125C16.3829 18.2352 14.5192 18.9 12.5002 18.9C10.4811 18.9 8.61738 18.2352 7.11588 17.1124C7.0209 17.0414 6.99137 16.9121 7.0488 16.8083C7.99896 15.0915 10.0826 13.9 12.5001 13.9C14.9177 13.9 17.0014 15.0916 17.9515 16.8084Z"
      fill="currentColor"
    />
    <path
      d="M17.5 4.90002H18.002C19.2134 4.90002 19.819 4.90002 20.2834 5.13203C20.7095 5.34497 21.0551 5.6905 21.268 6.11667C21.5 6.58098 21.5 7.18666 21.5 8.39802V8.90002M17.5 20.9H18.002C19.2134 20.9 19.819 20.9 20.2834 20.668C20.7095 20.4551 21.0551 20.1095 21.268 19.6834C21.5 19.2191 21.5 18.6134 21.5 17.402V16.9M7.5 4.90002H6.998C5.78664 4.90002 5.18096 4.90002 4.71665 5.13203C4.29048 5.34497 3.94495 5.6905 3.732 6.11667C3.5 6.58098 3.5 7.18666 3.5 8.39802V8.90002M7.5 20.9H6.998C5.78664 20.9 5.18096 20.9 4.71665 20.668C4.29048 20.4551 3.94495 20.1095 3.732 19.6834C3.5 19.2191 3.5 18.6134 3.5 17.402V16.9"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    /**
     * The size of the icon. Defaults to 24.
     * `1x(default), 1.5x, 2x, 3x, 4x`
     */
    size: {
      type: [Number, String],
      default: "1",
    },
  },
};
</script>
