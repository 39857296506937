import ApiService from '@shared/services/api'
import store from '@shared/store'
// import PrescriberService from '@/services/prescriber'
// import router from '@/router'
// import dayjs from "dayjs";
import { Capacitor } from '@capacitor/core';

// Important : distinction between this module and the "role module"
// This module is for the current user's role and permissions.
export default class AuthService extends ApiService {
    static async status() {
        try {
            let login_status = await super.get('/user/login_status?_format=json')
            return login_status.data
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    static async login(name, pass) {
        try {
            let login = await super.post('/user/login?_format=json', { name, pass })
            localStorage.setItem('csrf', login.data.csrf_token);
            // await this.initUser()  <= see about this later
            return Promise.resolve(login.data)
        } catch (error) {
            if (error.data && error.data.message == 'Sorry, unrecognized username or password.') {
                return Promise.reject('bad_login');
            }
            else {
                return Promise.reject(error)
            }
        }
    }

    static async profile() {
        try {
            let profile = await super.get('/user/profile')
            return Promise.resolve(profile.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    static async alerts() {
        try {
            let alerts = await super.get('/user/alerts')
            return Promise.resolve(alerts.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    static async logout() {
        // let csrf_token = localStorage.getItem('csrf');
        // let logout_token = localStorage.getItem('logout_token');
        try {
            await super.post('/user/logout')
            return Promise.resolve('logout');
        } catch (error) {
            return Promise.reject(error)
        }
    }

    static async resetPassword(login) {
        try {
            let url = '/user/reset-password?platform='+ Capacitor.getPlatform();
            const response = await super.post(url, {login: login})
            return Promise.resolve(response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    static async changePassword(data) {
        try {
            let req = await super.post('/user/change-password', data)

            return Promise.resolve(req.data)
        } catch (error) {
            if (error.response && error.response.data.error == 'bad_password') {
                return Promise.reject('bad_password');
            }
            else {
                return Promise.reject(error)
            }
        }
    }

    static async updateUser(type, uuid, data) {
        try {
            let req = await super.restPatch(type + '/' + uuid, data)
            return Promise.resolve(req.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    static async signup(data) {
        try {
            await super.post('/user/signup', data)
            return Promise.resolve('ok')
        } catch (error) {
            if (error.data && error.data.error == 'user_already_exists') {
                return Promise.reject('user_already_exists');
            }
            else {
                return Promise.reject(error)
            }
        }
    }

    static async lookup(data) {
        try {
            data.target_user_type = store.getters.getBaseConfigValue('targetUserType');
            let req = await super.post('/user/lookup', data)
            return Promise.resolve(req.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}