<template>
  <svg
    :width="size + 'em'"
    :height="size + 'em'"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.76368 19L7.36567 15.96C7.15008 15.8808 6.94677 15.7858 6.75572 15.675C6.56468 15.5642 6.37828 15.4454 6.19652 15.3187L3.23632 16.5063L0.5 11.9937L3.06219 10.1412C3.04561 10.0304 3.03731 9.92338 3.03731 9.82015V9.17985C3.03731 9.07662 3.04561 8.96958 3.06219 8.85875L0.5 7.00625L3.23632 2.49375L6.19652 3.68125C6.37894 3.55458 6.56965 3.43583 6.76866 3.325C6.96766 3.21417 7.16667 3.11917 7.36567 3.04L7.76368 0H13.2363L13.6343 3.04C13.8499 3.11917 14.0532 3.21417 14.2443 3.325C14.4353 3.43583 14.6217 3.55458 14.8035 3.68125L17.7637 2.49375L20.5 7.00625L17.9378 8.85875C17.9544 8.96958 17.9627 9.07662 17.9627 9.17985V9.82015C17.9627 9.92338 17.9461 10.0304 17.9129 10.1412L20.4751 11.9937L17.7388 16.5063L14.8035 15.3187C14.6211 15.4454 14.4304 15.5642 14.2313 15.675C14.0323 15.7858 13.8333 15.8808 13.6343 15.96L13.2363 19H7.76368ZM10.5498 12.825C11.5116 12.825 12.3325 12.5004 13.0124 11.8513C13.6924 11.2021 14.0323 10.4183 14.0323 9.5C14.0323 8.58167 13.6924 7.79792 13.0124 7.14875C12.3325 6.49958 11.5116 6.175 10.5498 6.175C9.57131 6.175 8.7461 6.49958 8.07413 7.14875C7.40216 7.79792 7.0665 8.58167 7.06717 9.5C7.06717 10.4183 7.40282 11.2021 8.07413 11.8513C8.74544 12.5004 9.57065 12.825 10.5498 12.825Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    /**
     * The size of the icon. Defaults to 24.
     * `1x(default), 1.5x, 2x, 3x, 4x`
     */
    size: {
      type: [Number, String],
      default: "1",
    },
  },
};
</script>
