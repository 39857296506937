import api from "@shared/services/api"

const state = {
}

const mutations = {
}

const getters = {
}

const actions = {
    async "activeQueue/transferAll"({ commit, dispatch }, data) {
        let entityType = data._entityType;
        delete data._entityType;
        delete data._context;
    
        const _data = api.preProcessData(data);

        return new Promise((resolve, reject) => {
          api.restPost('active_queue/transfer_all', _data)
          .then((resp) => {
            dispatch('dataList/refreshByType', entityType)
            resolve(resp);
          })
          .catch((e) => {
            reject(e)
          })
        });
      },
}

export default {
    state,
    getters,
    mutations,
    actions
}

