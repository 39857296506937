import { defineAsyncComponent } from "vue";

export default {
  import: (app) => {
    app.component(
      "admin.patient.devices",
      defineAsyncComponent(() =>
        import("@admin/components/patient/Devices.vue")
      )
    );

    app.component(
      "admin.patient.episodes-of-care",
      defineAsyncComponent(() =>
        import("@admin/components/patient/EpisodesOfCare.vue")
      )
    );
    app.component(
      "admin.patient.devices",
      defineAsyncComponent(() =>
        import("@admin/components/patient/Devices.vue")
      )
    );

    app.component(
      "admin.home",
      defineAsyncComponent(() => import("@admin/components/home/Home.vue"))
    );
    app.component(
      "admin.psad-parameters.reminders",
      defineAsyncComponent(() =>
        import("@admin/components/psad_parameters/Reminders.vue")
      )
    );
    app.component(
      "admin.psad-parameters.swagger",
      defineAsyncComponent(() =>
        import("@admin/components/psad_parameters/Swagger.vue")
      )
    );
    app.component(
      "admin.psad-parameters.documentation-index",
      defineAsyncComponent(() =>
        import("@admin/components/psad_parameters/DocumentationIndex.vue")
      )
    );
    app.component(
      "admin.psad-parameters.webhooks-status",
      defineAsyncComponent(() =>
        import("@admin/components/psad_parameters/WebhookStatus.vue")
      )
    );

    app.component(
      "admin.generate-api-key-field",
      defineAsyncComponent(() =>
        import("@admin/components/fields/GenerateApiKey.vue")
      )
    );

    app.component(
      "auth.layout",
      defineAsyncComponent(() => import("@admin/components/auth/Layout.vue"))
    );

    app.component(
      "notifications.alerts",
      defineAsyncComponent(() =>
        import("@admin/components/notifications/Alerts.vue")
      )
    );
    return app;
  },
};
