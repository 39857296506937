<template>
  <svg
    :width="size + 'em'"
    :height="size + 'em'"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8.5"
      cy="10.9"
      r="3"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.2119 13.8404C13.0039 13.1095 13.5 12.0627 13.5 10.9C13.5 10.3033 13.3693 9.73713 13.135 9.22851C13.3213 9.13334 13.5184 9.05722 13.7235 9.00225C14.492 8.79633 15.3109 8.90413 16 9.30196C16.689 9.69978 17.1918 10.355 17.3977 11.1236C17.6037 11.8921 17.4959 12.711 17.098 13.4C16.7002 14.0891 16.045 14.5919 15.2764 14.7978C14.5079 15.0037 13.689 14.8959 13 14.4981C12.6986 14.3241 12.4328 14.1008 12.2119 13.8404ZM14.2411 10.9341C14.4973 10.8655 14.7703 10.9014 15 11.034C15.2296 11.1666 15.3972 11.385 15.4659 11.6412C15.5345 11.8974 15.4986 12.1703 15.366 12.4C15.2334 12.6297 15.015 12.7973 14.7588 12.866C14.5026 12.9346 14.2296 12.8987 14 12.7661C13.7703 12.6334 13.6027 12.415 13.534 12.1589C13.4654 11.9027 13.5013 11.6297 13.6339 11.4C13.7665 11.1703 13.985 11.0027 14.2411 10.9341Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.64222 20.9H12.3579C12.2146 20.3014 11.9399 19.5951 11.4285 19.0268C10.8887 18.427 10.0154 17.9 8.50005 17.9C6.98471 17.9 6.1114 18.427 5.57164 19.0268C5.06015 19.5951 4.78549 20.3014 4.64222 20.9ZM4.08502 17.6889C5.0212 16.6486 6.44384 15.9 8.50005 15.9C10.5562 15.9 11.9789 16.6486 12.9151 17.6889C13.8198 18.6942 14.1999 19.8919 14.364 20.7128C14.6187 21.9861 13.5628 22.9 12.5 22.9H4.50005C3.43729 22.9 2.38143 21.9861 2.63605 20.7128C2.80023 19.8919 3.18032 18.6942 4.08502 17.6889Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.202 21.9C15.3804 21.565 15.4533 21.1612 15.3636 20.7128C15.1994 19.8919 14.8193 18.6942 13.9146 17.6889C13.4697 17.1945 12.9149 16.766 12.2344 16.4524C12.8638 16.1094 13.6162 15.9 14.4996 15.9C17.4481 15.9 18.6972 18.1588 19.1623 19.4699C19.641 20.8195 18.5128 21.9 17.3668 21.9H15.202Z"
      fill="currentColor"
    />
    <path
      d="M18.5 4.90002V8.90002"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M20.5 6.90002L16.5 6.90002"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    /**
     * The size of the icon. Defaults to 24.
     * `1x(default), 1.5x, 2x, 3x, 4x`
     */
    size: {
      type: [Number, String],
      default: "1",
    },
  },
};
</script>
