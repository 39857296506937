import api from "@shared/services/api"
import * as immutable from 'object-path-immutable'
import cloneDeep from "lodash/cloneDeep";
import { deserialize } from "deserialize-json-api";

const SET_ENTITY = 'SET_ENTITY';
const DELETE_ENTITY = 'DELETE_ENTITY';
const SET_PAGE_CONTEXT = 'SET_PAGE_CONTEXT';
const SET_ROW_CONTEXT = 'SET_ROW_CONTEXT';
const SET_SUB_CONTEXT = 'SET_SUB_CONTEXT';

const mutations = {
  [SET_ENTITY] (state, payload) {
    const path = `${payload.entityType}.${payload.uuid}`;
    state.entities      = immutable.set(state.entities, path, payload.entity);
    state.entities_json = immutable.set(state.entities_json, path, payload.json);
  },
  [DELETE_ENTITY] (state, payload) {
    const path = `${payload.entityType}.${payload.uuid}`;
    state.entities      = immutable.del(state.entities, path);
    state.entities_json = immutable.del(state.entities_json, path);
  },
  [SET_PAGE_CONTEXT] (state, payload) {
    state.pageContextEntityType = payload.entityType;
    state.pageContextEntityId = payload.uuid;
    state.pageContextParams = payload.params;
    state.subContext = {}
  },
  [SET_ROW_CONTEXT] (state, payload) {
    state.rowContextEntityType = payload.entityType;
    state.rowContextEntityId = payload.uuid;
  },
  [SET_SUB_CONTEXT] (state, payload) {
    state.subContext = payload
  }
};

const state = {
  entities      : {},
  entities_json : {},
  subContext    : {},
  
  pageContextEntityType : undefined,
  pageContextEntityId   : undefined,
  pageContextParams     : undefined,
  
  rowContextEntityType  : undefined,
  rowContextEntityId    : undefined,
  rowContextParams      : undefined
};

const getters = {
  getEntity: (state) => (entityType, uuid, format) => {
    let store_format = format == 'json' ? 'entities_json' : 'entities'
    if (!(entityType in state[store_format])) return [];
    if (!(uuid in state[store_format][entityType])) return [];
    return state[store_format][entityType][uuid];
  },
  getEntityField: (state) => (entityType, uuid, field) => {
    if (!(entityType in state.entities)) return undefined;
    if (!(uuid in state.entities[entityType])) return undefined;

    let data = state.entities[entityType][uuid].get(field);
    // I'm really not sure about this
    if (Array.isArray(data) && data.length == 1) {
      return data[0];
    }
    return data
  },
  getContextEntityType: (state) => (context) => {
    if (context == 'page') {
      return state.pageContextEntityType;
    }
    else if (context == 'row') {
      return state.rowContextEntityType;
    }
    else {
      throw new Error('Context is not set');
    }
  },
  getContextEntity: (state) => (context, format) => {
    format = format ?? 'model'
    if (context == 'page') {
      return getters.getEntity(state)(state.pageContextEntityType, state.pageContextEntityId, format);
    }
    else if (context == 'row') {
      return getters.getEntity(state)(state.rowContextEntityType, state.rowContextEntityId, format);
    }
    else {
      throw new Error('Context is not set');
    }
  },
  getContextEntityField: (state) => (context, field) => {
    if (context == 'page') {
      return getters.getEntityField(state)(state.pageContextEntityType, state.pageContextEntityId, field);
    }
    else if (context == 'row') {
      return getters.getEntityField(state)(state.rowContextEntityType, state.rowContextEntityId, field);
    }
    else {
      throw new Error('Context is not set');
    }
  },
  getSubContext: (state) => {
    return state.subContext
  },
};


const actions = {
  async "entityType/get"({ commit }, payload) {
    let entityType = payload._entityType;
    let entityId = payload._entityId;
    let params = payload._params;
    
    return new Promise((resolve, reject) => {
      api.jsonApiEntity(entityType, entityId, params)
      .then((resp) => {
        commit(SET_ENTITY, {
          entityType: entityType,
          uuid: entityId,
          entity: resp.model,
          json: deserialize(resp.json),
        });

        resolve(resp);
      })
      .catch((e) => {
        reject(e)
      })
    });
  },
  async "entityType/setPageContext"({ commit }, payload) {
    let entityType = payload._entityType;
    let entityId = payload._entityId;
    let params = payload._params;
    
    commit(SET_PAGE_CONTEXT, {
      entityType: entityType,
      uuid: entityId,
      params: params
    });
  },
  async "entityType/setRowContext"({ commit, getters }, payload) {
    let entityType = payload._entityType;
    let entityId = payload._entityId;
    let code = payload._code;
    let _data = getters.getDataListItem(code, entityId);
    if (!_data) {
      throw new Error('Data list item not found');
    }

    commit(SET_ENTITY, {
      entityType: entityType,
      uuid: entityId,
      entity: _data
    });

    commit(SET_ROW_CONTEXT, {
      entityType: entityType,
      uuid: entityId
    });
  },
  "entityType/setManualRowContext"({ commit }, payload) {
    commit(SET_ENTITY, {
      entityType: payload.entityType,
      uuid: payload.entityId,
      entity: payload.data
    });

    commit(SET_ROW_CONTEXT, {
      entityType: payload.entityType,
      uuid: payload.entityId
    });
  },
  async "entityType/reloadContext"({ commit, dispatch, state }) {
    if (!state.pageContextEntityType || !state.pageContextEntityId) return false;
    dispatch('entityType/get', {
      _entityType: state.pageContextEntityType,
      _entityId: state.pageContextEntityId,
      _params: state.pageContextParams,
      });
  },
  async "entityType/clearCurrent"({ commit }) {
    commit(CLEAR_CURRENT_ENTITY);
  },
  async "entityType/post"({ commit, dispatch }, data) {
    let entityType = data._entityType;
    delete data._entityType;
    delete data._context;
    
    return new Promise((resolve, reject) => {
      api.restPost(entityType, data)
      .then((resp) => {
        dispatch('dataList/refreshByType', entityType)
        resolve(resp);
      })
      .catch((e) => {
        reject(e)
      })
    });
  },
  async "entityType/patch"({ commit, dispatch, getters }, data) {
    let entityType = data._entityType;
    let entityId = data._entityId;
    delete data._entityType;
    delete data._entityId;
    delete data._context;

    // Object.keys(data).forEach((key) => {
    //   if (typeof data[key] == 'object' && data[key] != null && 'handler' in data[key]) {
    //     data[key] = getters[data[key].handler]
    //   }
    // });

    return new Promise((resolve, reject) => {
      api.restPatch(entityType + '/' + entityId, data)
      .then((resp) => {
        dispatch('dataList/refreshByType', entityType)
        dispatch('entityType/reloadContext')
        resolve(resp);
      })
      .catch((e) => {
        reject(e)
      })
    });
  },
  async "entityType/delete"({ commit, dispatch }, payload) {
    let entityType = payload._entityType;
    let entityId = payload._entityId;
    
    return new Promise((resolve, reject) => {
      api.restDelete(entityType + '/' + entityId)
      .then((resp) => {
        dispatch('dataList/refreshByType', entityType)
        resolve(resp);
      })
      .catch((e) => {
        reject(e)
      })
    });
  },
  // The 2 methods below could be removed in favor of a simple call to PATCH with arg { status: true|false } 
  // (this call can be done directly from YAML, we really do'nt need this. Maybe I will even keep it as an example of what we DONT need)
  async "entityType/disable"({ commit, dispatch }, payload) {
    let entityType = payload._entityType;
    let entityId = payload._entityId;
    
    return new Promise((resolve, reject) => {
      api.restPatch(entityType + '/' + entityId, {status: false})
      .then((resp) => {
        commit(SET_ENTITY, resp.data);
        dispatch('dataList/refreshByType', entityType)
        resolve(resp);
      })
      .catch((e) => {
        reject(e)
      })
    });
  },
  async "entityType/enable"({ commit, dispatch }, payload) {
    let entityType = payload._entityType;
    let entityId = payload._entityId;
    
    return new Promise((resolve, reject) => {
      api.restPatch(entityType + '/' + entityId, {status: true})
      .then((resp) => {
        commit(SET_ENTITY, resp.data);
        dispatch('dataList/refreshByType', entityType)
        resolve(resp);
      })
      .catch((e) => {
        reject(e)
      })
    });
  },
  async "entityType/delete"({ commit, dispatch }, payload) {
    let entityType = payload._entityType;
    let entityId = payload._entityId;
    
    return new Promise((resolve, reject) => {
      api.restDelete(entityType + '/' + entityId)
      .then((resp) => {
        commit(DELETE_ENTITY, payload);
        dispatch('dataList/refreshByType', entityType)
        resolve(resp);
      })
      .catch((e) => {
        reject(e)
      })
    });
  },
  async "entityType/download"({ commit, dispatch, getters }, payload) {
    let entityType = payload._entityType;
    let entityId = payload._entityId;
    let fileParams = getters.getEntityField(entityType, entityId, payload.fileField);
    // console.log(fileParams.uri)
    window.open(fileParams.uri);
  },
  //AS--> Update the value of a key in the subContext. Payload is an array with 2 elements: the key and the value [key, value]
  async "entityType/setSubContextField"({ state, commit }, payload) {

    //AS--> Clone the subContext to avoid modifying it directly
    let subContext = cloneDeep(state.subContext)

    //AS--> If the key does not exist or the value is different, update the subContext
    if(!subContext[payload[0]] || subContext[payload[0]] != payload[1]){
      subContext[payload[0]] = payload[1]
      commit(SET_SUB_CONTEXT, subContext)
    }

  }
};


export default {
    state,
    getters,
    actions,
    mutations
}