import api from "@shared/services/api";
import * as immutable from "object-path-immutable";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const RESET_ORDER = "RESET_ORDER";
const SET_ORDER_CONFIG = "SET_ORDER_CONFIG";
const SET_CURRENT_ORDER_FIELD = "SET_CURRENT_ORDER_FIELD";
const SET_CURRENT_ORDER_ITEM = "SET_CURRENT_ORDER_ITEM";
const SET_STEP = "SET_STEP";

const mutations = {
  [SET_ORDER_CONFIG](state, payload) {
    state.config = payload;
  },
  [SET_STEP](state, payload) {
    state.step = payload;
  },
  [RESET_ORDER](state) {
    state.config = {};
    state.order_data = {};
    state.items = {};
    state.step = 0;
  },
  [SET_CURRENT_ORDER_FIELD](state, payload) {
    state.order_data[payload.field] = payload.value;
  },
  [SET_CURRENT_ORDER_ITEM](state, payload) {
    if (payload.item.uuid in state.items) {
      if (payload.variation.uuid in state.items[payload.item.uuid].variations) {
        state.items = immutable.set(
          state.items,
          [payload.item.uuid, "variations", payload.variation.uuid, "quantity"],
          payload.quantity
        );
      } else {
        state.items = immutable.set(
          state.items,
          [payload.item.uuid, "variations", payload.variation.uuid],
          { variation: payload.variation, quantity: payload.quantity }
        );
      }
    } else {
      state.items = immutable.set(state.items, [payload.item.uuid], {
        item: payload.item,
        variations: {
          [payload.variation.uuid]: {
            variation: payload.variation,
            quantity: payload.quantity,
          },
        },
      });
    }
    if (
      state.items[payload.item.uuid].variations[payload.variation.uuid]
        .quantity <= 0
    ) {
      state.items = immutable.del(state.items, [
        payload.item.uuid,
        "variations",
        payload.variation.uuid,
      ]);
    }
    if (Object.keys(state.items[payload.item.uuid].variations).length <= 0) {
      state.items = immutable.del(state.items, [payload.item.uuid]);
    }
  },
};

const state = {
  config: {},
  step: 0,
  order_data: {},
  items: {},
  state_colors: {
    draft: "grey",
    placed: "green",
    validated: "green",
    fulfillment: "blue",
    completed: "green",
    canceled: "grey",
  },
};

const getters = {
  getOrderConfig: (state) => {
    return state.config;
  },
  getOrderPages: (state) => {
    return state.config.pages;
  },
  getOrderStep: (state) => {
    return state.step;
  },
  getCurrentOrderField: (state) => (field) => {
    return state.order_data[field];
  },
  getCurrentOrderItems: (state) => {
    return state.items;
  },
  getCurrentOrderItemQuantity: (state) => (itemUuid, variationUuid) => {
    if (
      itemUuid in state.items &&
      variationUuid in state.items[itemUuid].variations
    ) {
      return state.items[itemUuid].variations[variationUuid].quantity;
    } else {
      return 0;
    }
  },
  currentOrderHasOneItem: (state) => {
    return Object.keys(state.items).length >= 1;
  },
  currentOrderHasAddress: (state) => {
    return state.order_data.shipping_address;
  },
  getOrderStateColor: (state) => (stateName) => {
    return state.state_colors[stateName];
  },
};

const actions = {
  async "order/start"({ commit, getters, dispatch }, payload) {
    commit("RESET_ORDER");

    const boConfig = getters.getBOOrderConfig(payload.code);

    commit("SET_ORDER_CONFIG", boConfig.params);

    commit("SET_CURRENT_ORDER_FIELD", {
      field: "date",
      value: dayjs().format("YYYY-MM-DDTHH:mm"),
    });

    commit("SET_MODAL_PARAMS", {
      open: true,
      closeBtn: false,
      headerBar: false,
      vuetifyParams: {
        fullscreen: true,
        transition: "full-screen-dialog-transition",
      },
      subVuetifyParams: {
        noPadding: true,
      },
      content: [
        {
          module: getters.getComponent("order"),
          context: "page",
        },
      ],
    });
  },
  async "order/nextStep"({ commit, getters, dispatch }, payload) {
    commit("SET_STEP", getters.getOrderStep + 1);
  },
  async "order/prevStep"({ commit, getters, dispatch }, payload) {
    commit("SET_STEP", getters.getOrderStep - 1);
  },
  async "order/setStep"({ commit, getters, dispatch }, payload) {
    commit("SET_STEP", payload.step);
  },
  async "order/setTarget"({ commit, getters, dispatch }, payload) {
    if (payload.target && payload.target == "chest") {
      commit("SET_CURRENT_ORDER_FIELD", {
        field: "order_target_patient",
        value: null,
      });
      commit("SET_CURRENT_ORDER_FIELD", {
        field: "order_target_chest",
        value: true,
      });
    } else {
      commit("SET_CURRENT_ORDER_FIELD", {
        field: "order_target_chest",
        value: false,
      });
      commit("SET_CURRENT_ORDER_FIELD", {
        field: "order_target_patient",
        value: payload.item,
      });
    }
  },
  async "order/setType"({ commit, getters, dispatch }, payload) {
    commit("SET_CURRENT_ORDER_FIELD", { field: "order_type", value: payload });
  },
  async "order/setCartItem"({ commit, getters, dispatch }, payload) {
    commit("SET_CURRENT_ORDER_ITEM", {
      item: payload.item,
      variation: payload.variation,
      quantity: payload.quantity,
    });
  },
  async "order/setCurrentOrderField"({ commit, getters, dispatch }, payload) {
    commit("SET_CURRENT_ORDER_FIELD", payload);
  },
  async "order/submit"({ commit, getters, dispatch }, payload) {
    const order = {
      shipping_address: getters.getCurrentOrderField("shipping_address"),
      comment: getters.getCurrentOrderField("comment"),
    };

    const orderItems = [];

    Object.keys(getters.getCurrentOrderItems).forEach((itemUuid) => {
      Object.keys(getters.getCurrentOrderItems[itemUuid].variations).forEach(
        (variationUuid) => {
          orderItems.push({
            item: itemUuid,
            variation: variationUuid,
            quantity:
              getters.getCurrentOrderItems[itemUuid].variations[variationUuid]
                .quantity,
          });
        }
      );
    });

    order.items = orderItems;

    api.restPost("fo-order", order).then((response) => {
      dispatch("dataList/refreshByType", "commerce_order");
      dispatch('auth/getProfile', true);
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
