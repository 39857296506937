import ApiService from '@shared/services/api'


export default class SignupRequestService extends ApiService {

    static async get() {
        try {
            let signups = await super.get('/jsonapi/signup-request')
            return Promise.resolve(signups.data.model)
        } catch (error) {
            return Promise.reject(error)
        }

    }

    // Not rest, refact. me
    static async post(data) {
        const _data = {
            email: {
                value: data.email
            },
            first_name: {
                value: data.first_name
            },
            last_name: {
                value: data.last_name
            },
            phone: {
                value: data.phone
            }
        };

        try {
            let response = await super.post('/entity/signup-request', _data)
            return Promise.resolve(response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}