import api from "@shared/services/api"
import cloneDeep from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'
import handlers from "@shared/mixins/handlers"
import forEach from 'lodash/forEach'

const SET_QUESTIONNAIRE             = 'SET_QUESTIONNAIRE';
const SET_QUESTIONNAIRE_PAGES       = 'SET_QUESTIONNAIRE_PAGES';
const SET_QUESTIONNAIRE_LOADING     = 'SET_QUESTIONNAIRE_LOADING';
const SET_QUESTIONNAIRE_ACTUAL_PAGE = 'SET_QUESTIONNAIRE_ACTUAL_PAGE';
const SET_QUESTIONNAIRE_RESPONSES   = 'SET_QUESTIONNAIRE_RESPONSES';
const SET_QUESTIONNAIRE_SAVING      = 'SET_QUESTIONNAIRE_SAVING';
const SET_QUESTIONNAIRE_SUBMIT      = 'SET_QUESTIONNAIRE_SUBMIT';
const SET_QUESTIONNAIRE_USER        = 'SET_QUESTIONNAIRE_USER';
const SET_QUESTIONNAIRE_PRESETS     = 'SET_QUESTIONNAIRE_PRESETS';


const mutations = {
    [SET_QUESTIONNAIRE] (state, questionnaire) {
        state.questionnaire = questionnaire;
    },
    [SET_QUESTIONNAIRE_LOADING] (state, loading) {
        state.loading = loading;
    },
    [SET_QUESTIONNAIRE_PAGES] (state, pages) {
        state.pages = pages;
    },
    [SET_QUESTIONNAIRE_ACTUAL_PAGE] (state, actualPage) {
        state.actualPage = actualPage;
    },
    [SET_QUESTIONNAIRE_RESPONSES] (state, responses) {
        state.responses = responses;
    },
    [SET_QUESTIONNAIRE_SAVING] (state, saving) {
        state.saving = saving;
    },
    [SET_QUESTIONNAIRE_SUBMIT] (state, submit) {
        state.submit = submit;
    },
    [SET_QUESTIONNAIRE_USER] (state, user) {
        state.user = user;
    },
    [SET_QUESTIONNAIRE_PRESETS] (state, presets) {
        state.presets = presets;
    }
};



const state = {
    loading       : false,
    questionnaire : null,
    pages         : null,
    actualPage    : 0,
    responses     : {},
    saving        : 0,
    submit        : null,
    user          : null,
    presets       : {},
    notQuestions  : ['text','page','section','button']
};


const getters = {
    "questionnaire_getNbrSteps": (state) => {
        let nbrSteps = 0
        if(state.pages){
            state.pages.forEach((page) => {
                if(page.page_count_in_steps){
                    nbrSteps++
                }
            })
        }
        return nbrSteps
    },
    "questionnaire_stepNum": (state, getters) => {
        let stepNum = 0
        if(state.pages){
            state.pages.forEach((page, key) => {
                if(page.page_count_in_steps && key <= state.actualPage){
                    stepNum++
                }
            })
        }
        return stepNum
    },
    "questionnaire_getResponse": (state) => (system_name) => {
        return state.responses[system_name] ?? null
    }
};


const actions = {
    "questionnaire/setPresets" ({ commit }, presets) {
        commit(SET_QUESTIONNAIRE_PRESETS, presets);
    },
    "questionnaire/init" ({ commit, state, getters, dispatch }, code) {

        //AS--> Get the presets params
        let presets = state.presets
        //AS--> If the preset are not corresponding with the actual questionnaire, we reset it
        //AS--> Param 'questionnaire' is required in presets to ensure the correspondance between preset and actual questionnaire
        if(!presets.questionnaire || presets.questionnaire != code) presets = {}

        //AS--> Reset the questionnaire
        commit(SET_QUESTIONNAIRE_LOADING, true)
        commit(SET_QUESTIONNAIRE, null)
        commit(SET_QUESTIONNAIRE_PAGES, null)
        commit(SET_QUESTIONNAIRE_ACTUAL_PAGE, 0)
        commit(SET_QUESTIONNAIRE_RESPONSES, presets.responses ?? {})
        commit(SET_QUESTIONNAIRE_SAVING, 0)
        commit(SET_QUESTIONNAIRE_SUBMIT, null)
        commit(SET_QUESTIONNAIRE_USER, null)
        commit(SET_QUESTIONNAIRE_PRESETS, {})

        //AS--> Set the user
        dispatch('questionnaire/set_user', presets.user_uuid ?? null)

        //AS--> Get the questionnaire
        api.jsonApi('questionnaire', {
            filters: [
                {
                    path: 'system_name',
                    value: code,
                }
            ],
            includes: [
                {
                    field: 'questionnaire_question_relations',
                    includes: [
                        {
                            field: 'question',
                            includes: 'field_proposals'
                        },
                    ]
                }
            ],
            page: {
               limit: 1
            }
        })
        .then(response => {

            //AS--> Set the questionnaire
            if(response.json?.data?.length){
                let questionnaire = response.json.data[0]
                let questions = questionnaire.questionnaire_question_relations
                delete questionnaire.questionnaire_question_relations
                commit(SET_QUESTIONNAIRE, questionnaire);

                //AS--> Sort the questions by weight
                questions = sortBy(questions, ['weight'])

                //AS--> Get the questions
                questions = questions.map((question) => {
                    let _question = question.question
                    delete question.question
                    _question.questionnaire_question_relations = question
                    return _question
                })

                //AS--> If first question isn't _type 'page', add a question with _type page at the beginning
                if(questions[0]._type !== 'page'){
                    questions.unshift({
                        _type               : 'page',
                        page_count_in_steps : false,
                        page_show_steps     : false
                    })
                }

                let pages = []
                let page  = null

                //AS--> Browse the questions and split them into pages
                questions.forEach((question) => {
                    if(question._type === 'page'){
                        page = question
                        page['questions'] = []
                        pages.push(page)
                    }else{
                        page.questions.push(question)
                    }
                })

                //AS--> Set the pages
                commit(SET_QUESTIONNAIRE_PAGES, pages);

            }
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            commit(SET_QUESTIONNAIRE_LOADING, false);
        })
    },
    "questionnaire/set_user" ({ state, commit, rootState }, user_uuid) {
        if(!user_uuid) user_uuid = rootState.auth.profile.uuid
        commit(SET_QUESTIONNAIRE_USER, user_uuid);
    },
    "questionnaire/next" ({ state, commit }) {
        if(state.actualPage < state.pages.length-1) commit(SET_QUESTIONNAIRE_ACTUAL_PAGE, state.actualPage + 1);
    },
    "questionnaire/prev" ({ state, commit }) {
        if(state.actualPage > 0) commit(SET_QUESTIONNAIRE_ACTUAL_PAGE, state.actualPage - 1);
    },
    "questionnaire/setResponse" ({ state, commit }, payload) {
        let reponses = cloneDeep(state.responses)
        reponses[payload.system_name] = payload.response
        commit(SET_QUESTIONNAIRE_RESPONSES, reponses);
    },
    "questionnaire/save" ({ state, commit, dispatch, rootState }) {

        //AS--> First of all, we check if a submit has already been created
        if(!state.submit){

            //AS--> Set the saving state
            commit(SET_QUESTIONNAIRE_SAVING, state.saving + 1)

            //AS--> If not, we create it
            api.restPost('questionnaire_submit', {
                date          : handlers.methods.getDateTimeNow(),
                questionnaire : state.questionnaire.system_name,
                user          : rootState.auth.profile.uuid
            })
            .then(response => {

                //AS--> Then we save the responses
                commit(SET_QUESTIONNAIRE_SUBMIT, response.data)
                dispatch('questionnaire/save');
                commit(SET_QUESTIONNAIRE_SAVING, state.saving - 1)
            })
            .catch(error => {
                console.log(error);
            })

        //AS--> If a submit has already been created, we save the responses
        }else{
            let responses = cloneDeep(state.responses)

            let post  = []
            let patch = []

            forEach(responses, (response, question_system_name) => {

                response.question = question_system_name
                response.response = response.value

                if(response.uuid){
                    patch.push(response)
                }else{
                    response.questionnaire_submit = state.submit.uuid
                    post.push(response)
                }
            })


            //AS--> Save the responses
            if(post.length){
                commit(SET_QUESTIONNAIRE_SAVING, state.saving + 1)
                api.restPost('questionnaire_response', post)
                .then(response => {
                    let responses = cloneDeep(state.responses)
                    response.data.forEach((item) => {
                        responses[item.question].uuid = item.uuid
                    })
                    commit(SET_QUESTIONNAIRE_RESPONSES, responses);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    commit(SET_QUESTIONNAIRE_SAVING, state.saving - 1)
                })
            }

            //AS--> Update the responses
            if(patch.length){
                commit(SET_QUESTIONNAIRE_SAVING, state.saving + 1)
                api.restPatch('questionnaire_response', patch)
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    commit(SET_QUESTIONNAIRE_SAVING, state.saving - 1)
                })
            }

        }


    }
};


export default {
    state,
    getters,
    actions,
    mutations
}