import RoleService from "@shared/services/role";

const SET_ROLES_LIST = 'SET_ROLES_LIST';
const SET_PERMISSIONS_LIST = 'SET_PERMISSIONS_LIST';

const mutations = {
    [SET_ROLES_LIST] (state, payload) {
      state.roles = payload
    },
    [SET_PERMISSIONS_LIST] (state, payload) {
      state.permissions = payload.all
      state.actives = payload.active
    }
};


const state = {
    roles: {},
    permissions: {},
    actives: {}
};


const getters = {
  getRoles: (state) => (getterStruct) => {
    if (Object.keys(state.roles).length === 0) return [];
    return state.roles.get(getterStruct);
  },
  getPermissions: (state) => {
    if (Object.keys(state.permissions).length === 0) return [];
    return state.permissions;
  },
  getRolePermissions: (state) => (rid) => {
    if (Object.keys(state.actives).length === 0) return [];
    return state.actives[rid];
  }
};


const actions = {
    async "role/getRoles"({ commit }) {
        return new Promise((resolve, reject) => {
          RoleService.get()
          .then((data) => {
            commit(SET_ROLES_LIST, data);
            resolve('ok');
          })
          .catch((e) => {
            reject(e)
          })
        });
    },
    async "role/getPermissions"({ commit }) {
        return new Promise((resolve, reject) => {
          RoleService.getPermissions()
          .then((data) => {
            commit(SET_PERMISSIONS_LIST, data);
            resolve('ok');
          })
          .catch((e) => {
            reject(e)
          })
        });
    },
    async "role/savePermissions"({ commit }, payload) {
        return new Promise((resolve, reject) => {
          RoleService.savePermissions(payload)
          .then((data) => {
            resolve('ok');
          })
          .catch((e) => {
            reject(e)
          })
        });
    }
};


export default {
    state,
    getters,
    actions,
    mutations
}