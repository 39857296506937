import api from "@shared/services/api"

const state = {
}

const mutations = {
}

const getters = {
}

const actions = {
    async "observationDefinition/getBySystemName"({ commit, dispatch }, systemName) {

        const params = {
            filters: [
                {
                    'path': 'system_name',
                    'value': systemName,
                }
            ]
        };

        return new Promise((resolve, reject) => {
            api.jsonApi('observation_definition', params)
            .then((resp) => {
                resolve(resp.model.models[0]);
            })
            .catch((e) => {
                reject(e)
            })
        });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}

