<template>
  <svg
    :width="size + 'em'"
    :height="size + 'em'"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 7.63193V7.11204C7 4.96614 7 3.8932 7.6896 3.29511C8.37919 2.69703 9.44136 2.84877 11.5657 3.15224L15.8485 3.76408C18.3047 4.11495 19.5327 4.29039 20.2664 5.13628C21 5.98217 21 7.22271 21 9.70377V15.2962C21 17.7773 21 19.0178 20.2664 19.8637C19.5327 20.7096 18.3047 20.885 15.8485 21.2359L11.5657 21.8478C9.44136 22.1512 8.37919 22.303 7.6896 21.7049C7 21.1068 7 20.0339 7 17.888V17.566"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M16 12.5L16.7809 11.8753L17.2806 12.5L16.7809 13.1247L16 12.5ZM4 13.5C3.44771 13.5 3 13.0523 3 12.5C3 11.9477 3.44771 11.5 4 11.5V13.5ZM12.7809 6.8753L16.7809 11.8753L15.2191 13.1247L11.2191 8.1247L12.7809 6.8753ZM16.7809 13.1247L12.7809 18.1247L11.2191 16.8753L15.2191 11.8753L16.7809 13.1247ZM16 13.5H4V11.5H16V13.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    /**
     * The size of the icon. Defaults to 24.
     * `1x(default), 1.5x, 2x, 3x, 4x`
     */
    size: {
      type: [Number, String],
      default: "1",
    },
  },
};
</script>
