import SignupRequestService from "@shared/services/signup_request";


const mutations = {

};


const state = {
};


const getters = {

};


const actions = {
    async "signup/signup"({ commit }, data) {
        return new Promise((resolve, reject) => {
          SignupRequestService.post(data)
          .then(async () => {
            resolve('ok');
          })
          .catch((e) => {
            reject(e)
          })
        });
    }
};


export default {
    state,
    getters,
    actions,
    mutations
}