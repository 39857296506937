import conditionsBlock from "@shared/mixins/conditions_block";

export default {
  name: "content-type",
  mixins: [conditionsBlock],
  props: {
    content: {
      type: Array,
      default: () => ([]),
    },
  },
  created() {
  },
  methods: {
  },
  computed: {
    getContent() {
      let content = [];
      this.content.forEach((element, k) => {
        let canShow = true;
        const kRandomized = k + Math.random(); // Give a randomized param to VueJS to force re-rendering
        if (!this.$store.getters.userHasPermission(element.permissions)) canShow = false;
        if (element.conditionsBlock && !this.getConditionsBlockResult(element.conditionsBlock)) canShow = false;
        if (canShow) content.push({ element, id: kRandomized });
      });

      return content;
    }
  },
};