<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size + 'em'"
    :height="size + 'em'"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </svg>
</template>

<script>
export default {
  props: {
    /**
     * The size of the icon. Defaults to 24.
     * `1x(default), 1.5x, 2x, 3x, 4x`
     */
    size: {
      type: [Number, String],
      default: "1",
    },
  },
};
</script>
