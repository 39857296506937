<template>
  <template v-for="elem in getContent" :key="elem.id">
    <!-- Uncomment this line for debugging. It will show you the name of every component rendered -->
  <!-- {{ $store.getters.getComponent(elem.element.module) }}
  {{ elem.element.content }} -->
   
    <component
      :is               = "$store.getters.getComponent(elem.element.module)"
      :content          = "elem.element.content ?? []"
      :vuetifyParams    = "elem.element.vuetifyParams ?? {}"
      :subVuetifyParams = "elem.element.subVuetifyParams ?? {}"
      :params           = "elem.element.params ?? {}"
      :context          = "elem.element.context ?? {}"
    />
  </template>
</template>

<script src="./ContentType.js"></script>
