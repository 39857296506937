<template>
  <svg
    :width="size + 'em'"
    :height="size + 'em'"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 9.90002L14.4558 14.4663C14.0299 15.1051 13.0728 15.0455 12.7294 14.3588L12.2706 13.4413C11.9272 12.7545 10.9701 12.6949 10.5442 13.3338L7.5 17.9"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="3.5"
      y="3.90002"
      width="18"
      height="18"
      rx="2"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    /**
     * The size of the icon. Defaults to 24.
     * `1x(default), 1.5x, 2x, 3x, 4x`
     */
    size: {
      type: [Number, String],
      default: "1",
    },
  },
};
</script>
