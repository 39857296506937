import ConfigService from "@shared/services/config"
import i18n from "@shared/i18n";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import cloneDeep from 'lodash/cloneDeep';
dayjs.extend(utc)

const SET_BASE_CONFIG = 'SET_BASE_CONFIG'
const SET_ENV_CONFIG  = 'SET_ENV_CONFIG'
const SET_BO_CONFIG   = 'SET_BO_CONFIG'
const SET_LOCALES     = 'SET_LOCALES'
const SET_COMPONENTS  = 'SET_COMPONENTS'
const SET_VAR         = 'SET_VAR'
const UNSET_VAR       = 'UNSET_VAR'

const state = {
    baseConfig : {},
    envConfig  : {},
    boConfig   : {},
    locales    : [],
    components : [],
    vars       : {}
}

const mutations = {
    [SET_BASE_CONFIG](state, baseConfig) {
        state.baseConfig = baseConfig;
    },
    [SET_ENV_CONFIG](state, envConfig) {
        state.envConfig = envConfig;
    },
    [SET_BO_CONFIG](state, boConfig) {
        state.boConfig = boConfig;
    },
    [SET_LOCALES](state, locales) {
        state.locales = locales;
    },
    [SET_COMPONENTS](state, components) {
        Object.keys(components).forEach((key) => {
            state.components.push(key);
        });
    },
    [SET_VAR](state, payload) {
        let vars = cloneDeep(state.vars)
        vars[payload.key] = payload.value
        state.vars = vars
    },
    [UNSET_VAR](state, key) {
        let vars = cloneDeep(state.vars)
        delete vars[key]
        state.vars = vars
    }
}

const getters = {
    getBaseConfig: state => state.baseConfig,
    getEnvConfig: state => state.envConfig,
    getBaseConfigValue: (state) => (key) => { return state.baseConfig[key] },
    getEnvConfigValue: (state) => (key) => {  return state.envConfig[key] },
    getBOConfig: state => state.boConfig,
    getBORoutes: state => state.boConfig.routes,
    getBOTheme: state => state.boConfig.theme,
    getBORouteByCode: state => (code) => {
        return state.boConfig.routes.find((route) => route.code === code);
    },
    getBOEncounterConfig: state => (system_name) => {
        return state.boConfig.routes.find((route) => route.type === 'encounter' && route.system_name == system_name);
    },
    getBOOrderConfig: state => (code) => {
        return state.boConfig.routes.find((route) => route.type === 'order' && route.code == code);
    },
    getBOAuth: state => state.boConfig.auth,
    getEntityValueObjectList: (state) => (entityType) => {
        let data = state.boConfig.entityValueObjects[entityType];
        return data;
    },
    getValueObjectList: (state, getters) => (key) => {
        if (key in state.boConfig.entityValueObjects) {
            return getters.getEntityValueObjectList(key);
        }
        let data = key.split('.').reduce((o,i)=>o[i], state.boConfig.valueObjects);
        let ret = [];
        Object.keys(data).forEach((key) => {
            ret.push({
                id: key,
                label: data[key]
            })
        });

        return ret;
    },
    getValueObjectLabel: (state, getters) => (key, id) => {
        if (!id) return null;
        return getters.getValueObjectList(key).find((item) => item.id === id).label;
    },
    getValidation: (state) => (payload) => {
        if (!('entityType' in payload)) return {};
        if (!('field' in payload)) return {};
        const entityType = payload.entityType;
        const field = payload.field;

        if (!(entityType in state.boConfig.validation)) return {};
        if (!(field in state.boConfig.validation[entityType])) return {};
        return state.boConfig.validation[entityType][field];
    },
    getAvailableLocales: state => state.locales,
    getComponent: (state, getters) => (key) => {
        const appName = getters.getBaseConfigValue('appName');
        // Try to load the component in the following order :
        const possibleComponents = [
            'customer.' + appName + '.' + key,
            'customer.' + key,
            appName + '.' + key,
            key
        ];
        
        let component = null;
        possibleComponents.forEach((possibleComponent) => {
            if (state.components.includes(possibleComponent)) {
                // Just a hint for local dev => sometime we may forget that a component is overloaded
                if (possibleComponent != key && getters.getEnvConfigValue('appEnv') == 'dev') {
                    console.log('Component ' + key + ' overloaded by ' + possibleComponent);
                }
                component =  possibleComponent;
            }
        });

        if(!component) console.error('========> Component ' + key + ' not found');
        return component;
    },
    getDateNow: () => {
        return dayjs().utc().format("YYYY-MM-DD\THH:mm:ss") + 'Z';
    }
}

const actions = {
    async "appp/initConfig"({ commit }, baseConfig = {}) {
        commit('SET_ENV_CONFIG', ConfigService.getEnvConfig());
        commit('SET_BASE_CONFIG', baseConfig)
    },
    async "app/initBOConfig"({ commit, getters }) {
        return new Promise((resolve) => {
            ConfigService.getBOConfig(getters.getBaseConfigValue('appName')).then((d) => {
                commit('SET_BO_CONFIG', d)
                resolve(d);
            });
        })
    },
    async "app/initI18n"({ commit, getters }) {
        return new Promise((resolve) => {
            ConfigService.getI18nConfig(
                getters.getBaseConfigValue('appName'), 
                getters.userGetLang
            ).then((d) => {
                const allMessages = {
                    ...d['trad_vendor_' + getters.userGetLang][getters.userGetLang],
                    ...d['trad_shared_'  + getters.userGetLang][getters.userGetLang],
                    ...d['trad_' + getters.getBaseConfigValue('appName') + '_' + getters.userGetLang][getters.userGetLang],
                    ...d['trad_' + getters.getEnvConfigValue('customerSystemName') + '_' + getters.userGetLang][getters.userGetLang],
                };

                commit(SET_LOCALES, d['trad_manifest']);
                
                i18n.global.setLocaleMessage(getters.userGetLang, allMessages);
                i18n.global.locale = getters.userGetLang;
                resolve();
            });
        })
    },
    async "app/registerComponents"({ commit }, components) {
        commit('SET_COMPONENTS', components)
    },
    "app/setVar"({ commit }, payload) {
        commit('SET_VAR', payload)
    },
    "app/unsetVar"({ commit }, key) {
        commit('UNSET_VAR', key)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}