<template>
  <svg
    :width="size + 'em'"
    :height="size + 'em'"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5 8.90002H5.5C4.94772 8.90002 4.5 9.34774 4.5 9.90002V18.9C4.5 19.4523 4.94772 19.9 5.5 19.9H19.5C20.0523 19.9 20.5 19.4523 20.5 18.9V9.90002C20.5 9.34774 20.0523 8.90002 19.5 8.90002ZM5.5 6.90002C3.84315 6.90002 2.5 8.24317 2.5 9.90002V18.9C2.5 20.5569 3.84315 21.9 5.5 21.9H19.5C21.1569 21.9 22.5 20.5569 22.5 18.9V9.90002C22.5 8.24317 21.1569 6.90002 19.5 6.90002H5.5Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 4.90002C11.9477 4.90002 11.5 5.34774 11.5 5.90002V7.90002H9.5V5.90002C9.5 4.24317 10.8431 2.90002 12.5 2.90002C14.1569 2.90002 15.5 4.24317 15.5 5.90002V7.90002H13.5V5.90002C13.5 5.34774 13.0523 4.90002 12.5 4.90002Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 10.9C13.0523 10.9 13.5 11.3477 13.5 11.9V17.9C13.5 18.4523 13.0523 18.9 12.5 18.9C11.9477 18.9 11.5 18.4523 11.5 17.9V11.9C11.5 11.3477 11.9477 10.9 12.5 10.9Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 14.9C8.5 14.3477 8.94772 13.9 9.5 13.9L15.5 13.9C16.0523 13.9 16.5 14.3477 16.5 14.9C16.5 15.4523 16.0523 15.9 15.5 15.9L9.5 15.9C8.94772 15.9 8.5 15.4523 8.5 14.9Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    /**
     * The size of the icon. Defaults to 24.
     * `1x(default), 1.5x, 2x, 3x, 4x`
     */
    size: {
      type: [Number, String],
      default: "1",
    },
  },
};
</script>
