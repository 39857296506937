import ApiService from '@shared/services/api'

// Important : distinction between this module and the "auth module"
// This module is for the general information about roles and permissions.
// I.E it is not about the current user's role and permissions.
// The "auth" module is for the current user's role and permissions.

export default class RoleService extends ApiService {

    static async get() {
        try {
            let roles = await super.jsonApi('user_role')
            return Promise.resolve(roles.model)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    static async getPermissions() {
        try {
            let permissions = await super.restGet('permissions')
            return Promise.resolve(permissions.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    static async savePermissions(payload) {
        try {
            let permissions = await super.restPost('permissions', payload)
            return Promise.resolve(permissions.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

}