import cloneDeep from "lodash/cloneDeep";

import ChartIcon from "@shared/components/icons/ChartIcon.vue";
import ExitIcon from "@shared/components/icons/ExitIcon.vue";
import GridIcon from "@shared/components/icons/GridIcon.vue";
import MedicalBagIcon from "@shared/components/icons/MedicalBagIcon.vue";
import ParametersIcon from "@shared/components/icons/ParametersIcon.vue";
import UserScanIcon from "@shared/components/icons/UserScanIcon.vue";
import PatientIcon from "@shared/components/icons/PatientIcon.vue";

export default {
  name: "Menu",
  components: {
    ChartIcon,
    ExitIcon,
    GridIcon,
    MedicalBagIcon,
    ParametersIcon,
    UserScanIcon,
    PatientIcon,
  },
  data() {
    return {
      isSubActive: {},
      localDrawer: this.drawer,
    };
  },
  props: {
    drawer: Boolean,
  },
  emits: ["update:drawer"],
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/accounts");
      });
    },
    setIsSubActive(path) {
      // If you need to add sub-routes : routes that should add the "active class" to their "parent" item in the menu,
      // add rules here
      // path will be the "menu" path, and this.$route.fullPath the current full path.
      // Set here rules that returns "true" (and then, add the "active class to the parent) when necessary
      // if (path == '/patients' && this.$route.fullPath.substr(0, 9) == '/patient/') this.isSubActive[path] = true;

      this.isSubActive[path] = false;
    },
    debug() {
      console.log("Routing : ", cloneDeep(this.$router.getRoutes()));
      console.log("Store : ", cloneDeep(this.$store.state));
    },
  },
  computed: {
    isLocalhost() {
      return window.location.href.includes("localhost");
    },
    getMenu() {
      let self = this;
      return this.$store.getters.getBORoutes.filter(function (m) {
        self.setIsSubActive(m.path);
        let access = true;
        if ("permissions" in m) {
          if (!self.$store.getters.userHasPermission(m.permissions))
            access = false;
        }
        return "menu" in m && access;
      });
    },
  },
  watch: {
    "$route.fullPath"() {
      let self = this;
      Object.keys(this.isSubActive).forEach(function (path) {
        self.setIsSubActive(path);
      });
    },
    drawer(newVal) {
      this.localDrawer = newVal;
    },
    localDrawer(newVal) {
      this.$emit("update:drawer", newVal);
    },
  },
};
