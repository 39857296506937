import yaml_mixin_handler from "@shared/mixins/yaml_mixin_handler";
import handlers from "@shared/mixins/handlers";

export default {
    mixins: [yaml_mixin_handler, handlers],
    methods: {
        getConditionsBlockResult(conditionsBlock) {
            let totalResult = true;
            if ('conditions' in conditionsBlock) {
              let conditionsResults = [];
              conditionsBlock.conditions.forEach((condition) => {
                if ('operator' in condition) {
                  switch (condition.operator) {
                    case '>':
                      if (this.resolveValue(condition.handler) > condition.value) {
                        conditionsResults.push(true);
                      }
                      else {
                        conditionsResults.push(false);
                      }
                      break;
                    case '<':
                      if (this.resolveValue(condition.handler) < condition.value) {
                        conditionsResults.push(true);
                      }
                      else {
                        conditionsResults.push(false);
                      }
                      break;
                    case '>=':
                      if (this.resolveValue(condition.handler) >= condition.value) {
                        conditionsResults.push(true);
                      }
                      else {
                        conditionsResults.push(false);
                      }
                      break;
                    case '<=':
                      if (this.resolveValue(condition.handler) <= condition.value) {
                        conditionsResults.push(true);
                      }
                      else {
                        conditionsResults.push(false);
                      }
                      break;
                    case '!=':
                      if (this.resolveValue(condition.handler) != condition.value) {
                        conditionsResults.push(true);
                      }
                      else {
                        conditionsResults.push(false);
                      }
                      break;
                  }
                }
                else {
                  if (this.resolveValue(condition.handler) == condition.value) {
                    conditionsResults.push(true);
                  }
                  else {
                    conditionsResults.push(false);
                  }
                }

              });
  
              if ('operator' in conditionsBlock) {
                if (conditionsBlock.operator === 'or') {
                  totalResult = conditionsResults.includes(true);
                }
              }
              else if (!('operator' in conditionsBlock) || conditionsBlock.operator === 'and') {
                totalResult = !conditionsResults.includes(false);
              }
            }

            return totalResult;
        }
    }
}